import React from 'react'
import styled from 'styled-components';
import { SplitColumn } from '../split-column';
import { colors } from '../../style/colors';
import CoverImage from '../cover-image';
import { MainButton } from '../../style/buttons';
import { SectionTitle } from '../../style/typography';
import { useSetRecoilState } from 'recoil';
import { showingContact } from '../../state/atoms';

const Content = styled.div`
  padding: 30% 10%;

  @media (max-width: 550px) {
      padding: 30px;
  }
`;
const Copy = styled.p`
    max-width: 500px;
    font-size: 16px;
`;
const VideoContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
`;
const Image = styled.img`
    margin: 0;
    width: 70%;
`;

const AboutContact = ({ data }) => {

    const setShowing = useSetRecoilState(showingContact)

    return (
        <SplitColumn>
            <VideoContainer>
                <Image src={require('../../images/pool.png')} />
            </VideoContainer> 
            <Content>
                <SectionTitle>{data.acf.closing_title}</SectionTitle>
                <Copy>{data.acf.closing_copy}</Copy>
                <MainButton onClick={() => setShowing(true)}>Get In Touch</MainButton>
            </Content>
        </SplitColumn>
    )
}

export default AboutContact