import React from 'react'
import { atom } from 'recoil'


export const showingContact = atom({
    key: "showingContact",
    default: false
})
export const showingTeamMember = atom({
    key: "showingTeamMember",
    default: false
})
export const colorScheme = atom({
    key: "colorScheme",
    default: 'dark'
})
export const nameField = atom({
    key: "name",
    default: ''
})
export const emailField = atom({
    key: "email",
    default: ''
})
export const teamItem = atom({
    key: "teamItem",
    default: {}
})