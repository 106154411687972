import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import { colors } from "../style/colors"
import { useSetRecoilState } from "recoil"
import { showingContact } from "../state/atoms"

const Container = styled(motion.div)`
  background: #fff;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: -1;
  padding: 30px;
  width: 70vw;
`
const MenuList = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  list-style: none;
  padding-top: 50px;
`
const MenuItem = styled(Link)`
  text-transform: uppercase;
  font-family: "Akkurat";
  font-weight: 500;
  color: ${colors.copy};
  margin-bottom: 10px;
    text-decoration: none;
  font-size: 18px;
`
const Item = styled.li`
  text-transform: uppercase;
  font-family: "Akkurat";
  font-weight: 500;
  color: ${colors.copy};
  margin-bottom: 10px;
    text-decoration: none;
  font-size: 18px;
`
const spring = {
  type: "spring",
  damping: 100,
  stiffness: 100,
}

const menu = {
  open: {
    opacity: 1,
    x: 0,
    transition: { staggerChildren: 0.07, delayChildren: 0.2, spring },
  },
  closed: {
    opacity: 0,
    x: "100%",
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
      spring,
      when: "afterChildren",
    },
  },
}

const Menu = ({ isOpen, close }) => {

    const setShowing = useSetRecoilState(showingContact)
    
    return (
        <Container
            variants={menu}
            initial="closed"
            animate={isOpen ? "open" : "closed"}
            transition={spring}
        >
            <MenuList>
                {menuList.map((item, index) => {
                    if (item.title === "Contact") {
                        return (
                            <Item
                                key={index}
                                initial="hidden"
                                onClick={() => {
                                    close()
                                    setShowing(true)
                                }}
                            >
                            {item.title}
                            </Item>
                        )
                    }
                    return (
                        <MenuItem
                            key={index}
                            initial="hidden"
                            to={item.link}
                            activeStyle={{ color: colors.main, textDecoration: 'underline', textDecorationColor: colors.accent }}

                        >
                            {item.title}
                        </MenuItem>
                    )
                })}
            </MenuList>
        </Container>
    )
}

export default Menu


const menuList = [
    {
      title: "About",
      link: '/about'
    },
    {
      title: "Team",
      link: "/team",
    },
    {
      title: "Services",
      link: "/services",
    },
    {
      title: "Projects",
      link: "/projects",
    },
    {
      title: "Contact",
      link: "http://beston.consulting/contact/",
    },
  ]