import styled from 'styled-components';

export const SectionTitle = styled.h1`
    font-size: 40px;
    line-height: 1.4;

    @media (max-width: 550px) {
        font-size: 35px;
    }
`;
export const NavTitle = styled.h1`
    font-size: 30px;
    font-weight: bold;
    line-height: 1.4;
    
`;