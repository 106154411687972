import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { useSetRecoilState } from "recoil"
import { showingContact } from "../state/atoms"

const HeaderContainer = styled.header`
  background: white;
  padding: 20px 3%;
  position: sticky;
  top: 0;
  z-index: 5;
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
const Logo = styled.img`
  max-width: 105px;
  margin: 0;
`;
const Menu = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  list-style: none;
  padding-top: 10px;
`
const MenuItem = styled.li`
  text-transform: uppercase;
  cursor: pointer;
  font-family: "Akkurat";
  font-weight: 600;
  margin-left: 20px;
  margin-bottom: 0;
  font-size: 16px;
`

const Header = () => {
  const setShowing = useSetRecoilState(showingContact)

  return (
    <HeaderContainer>
      <Link to="/">
        <Logo src={require('../images/logo.png')} />
      </Link>
      <Menu>
        {menu.map((item, index) => {
          if (item.title === "Contact") {
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  setShowing(true)
              }}  
              >
                  {item.title}
              </MenuItem>
            )
          }
          return (
            <MenuItem key={index}>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    fontWeight: 700,
                  }}
                  to={item.link}
                >
                  {item.title}
                </Link>
            </MenuItem>
          )
        })}
      </Menu>
    </HeaderContainer>
  )

}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const menu = [
  {
    title: "About",
    link: '/about'
  },
  {
    title: "Team",
    link: "/team",
  },
  {
    title: "Services",
    link: "/services",
  },
  {
    title: "Projects",
    link: "/projects",
  },
  {
    title: "Contact",
    link: "http://beston.consulting/contact/",
  },
]

export default Header