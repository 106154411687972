import React from 'react'
import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    height: 100%;
`
const Image = styled.img`
    width: 100%;
    height: 100%;
    margin: 0;
    object-fit: ${props => props.contain ? 'contain' : 'cover'};
`

const CoverImage = ({ image, contain }) => {
    return (
        <Container>
            <Image contain src={image} />
        </Container>
    )
}

export default CoverImage