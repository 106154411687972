import React, { useState } from 'react'
import styled from 'styled-components';
import { colors } from '../../style/colors';
import { MdClose } from 'react-icons/md'
import { motion } from 'framer-motion';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { showingContact, showingTeamMember, teamItem } from '../../state/atoms';
import emailjs from "emailjs-com"
import { navigate } from 'gatsby';
import Loader from 'react-loader-spinner';
import Img from "gatsby-image"

const Container = styled(motion.div)`
    position: fixed;
    height: 100vh;
    z-index: 0;
    width: 100%;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(20px);
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
`;
const InnerContainer = styled.div`
    background: white;
    padding: 60px;
    max-width: 900px;
    z-index: 1;
    max-height: 80vh;
    width: 100%;
    border: 5px solid #9DCFCA;
    position: relative;
    display: grid;
    grid-template-columns: 40% 60%;
    column-gap: 20px;
    overflow-y: scroll;


    @media (max-width: 650px) {
        
        grid-template-columns: 100%;
        margin-top: 50px;
    }

    @media (max-width: 550px) {
        padding: 60px 20px;
    }
`;
const Content = styled.div`
    flex: 1;
`;
const Close = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
`;
const ImageContainer = styled.div`
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    max-height: 400px;
    flex: 1;

    @media (max-width: 650px) {
        width: 100%;
    }
`;
const Name = styled.h5`
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 5px;

`;
const Role = styled.p`
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 5px;
`;
const Bio = styled.div`
    margin-top: 20px;

    p {
        font-size: 15px;
        width: 100%;
        line-height: 1.6;
        margin-bottom: 20px;
    }

`;
const Email = styled.a`
    font-size: 13px;
    text-decoration: underline;
    color: ${colors.copy};
    font-family: "Akkurat";
    font-weight: 600;
`;

const TeamMemberPopup = () => {


    const setShowing = useSetRecoilState(showingTeamMember)
    const item = useRecoilValue(teamItem)

    return (
        <Container
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
            <InnerContainer>
                <Close onClick={() => setShowing(false)}>
                    <MdClose size={30} />
                </Close>
                <ImageContainer>
                    <Img
                        style={{
                            width: "100%",
                            height: "100%",
                            margin: 0,
                            }}
                        fadeIn
                        objectFit="cover"
                        fluid={item.acf.photo.localFile.childImageSharp.fluid}
                    />
                </ImageContainer>
                <Content>
                    <Name>{item.title}</Name>
                    
                        <Role>{item.acf.role}</Role>
                        <Role>{item.acf.honours}</Role>
                        <Bio dangerouslySetInnerHTML={{ __html: item.content }}/>
                        {item.acf.email && <Email href={`mailto:${item.acf.email}`}>{item.acf.email}</Email>}
                </Content>
            </InnerContainer>
        </Container>
    )
}

export default TeamMemberPopup