import React, { useState } from 'react'
import styled from 'styled-components';
import { colors } from '../../style/colors';
import { MdClose } from 'react-icons/md'
import { motion } from 'framer-motion';
import { useSetRecoilState } from 'recoil';
import { showingContact } from '../../state/atoms';
import emailjs from "emailjs-com"
import { navigate } from 'gatsby';
import Loader from 'react-loader-spinner';
const Container = styled(motion.div)`
    position: fixed;
    height: 100vh;
    width: 100%;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(20px);
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
`;
const InnerContainer = styled.div`
    background: white;
    padding: 60px;
    max-width: 800px;
    width: 100%;
    border: 5px solid #9DCFCA;
    position: relative;

    @media (max-width: 550px) {
        padding: 60px 20px;
    }
`;
const Close = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
`;
const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
`;
const Input = styled.input`
    background: #9DCFCA30;
    border: none;
    outline: none;
    padding: 15px 20px;
    margin-bottom: 20px;
    font-weight: 400;
`;
const TextArea = styled.textarea`
    background: #9DCFCA30;
    border: none;
    outline: none;
    margin-bottom: 20px;
    font-weight: 400;
    padding: 15px 20px;
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const SubmitBtn = styled.button`
    width: max-content;
    color: white;
    cursor: pointer;
    background: ${colors.main};
    outline: none;
    border: none;
    padding: 10px 20px;
    font-weight: 400;
    margin-right: 20px;

    :disabled {
        background: #aaa;
        cursor: not-allowed;
    }
`;

const ContactPopup = () => {

    const [state, setState] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    })
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')

    const setShowing = useSetRecoilState(showingContact)

    const updateInput = (e) => {
        const name = e.target.name
        const value = e.target.value

        setState({...state, [name]: value})
    } 

    const disabled = () => {
        return state.name === "" || state.email === "" || state.message === ""
    }

    const handleSubmit = e => {
        e.preventDefault()
        setLoading(true)

        const {name, email, subject, message} = state

        const templateParams = {
            name,
            email,
            subject,
            message,
        }

        sendEmail(templateParams)
    }

    const sendEmail = (templateParams) => {

        const CONTACT_TEMPLATE = 'template_AHGrEUJ9'
        emailjs
        .send(
            "gmail",
            CONTACT_TEMPLATE,
            templateParams,
            "user_QZzzhKaisK76qBNkJ1TMM"
        )
        .then(
            response => {
                setLoading(false)
                setMessage("Submitted!")
                setTimeout(() => {
                    setShowing(false)
                }, 1500)
                // navigate("/thank-you")
            },
            err => {
                setLoading(false)
                // this.setState({ isLoading: false })
                setMessage("Uh oh, looks like something went wrong. Please try again")
                console.log("FAILED...", err)
            }
        )


    }

    return (
        <Container
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
            <InnerContainer>
                <Close onClick={() => setShowing(false)}>
                    <MdClose size={30} />
                </Close>
                <Form onSubmit={handleSubmit}>
                    <Input name="name" value={state.name} onChange={updateInput} placeholder="Your Name (required)" />
                    <Input type="email" name="email" value={state.email} onChange={updateInput} placeholder="Your Email (required)" />
                    <Input name="subject" value={state.subject} onChange={updateInput} placeholder="Subject" />
                    <TextArea name="message" value={state.message} onChange={updateInput} rows={5} placeholder="Your Message" />
                    <Row>
                        <SubmitBtn type="submit" disabled={disabled()}>Submit Message</SubmitBtn>
                        {loading ?
                            <Loader 
                                type="Oval"
                                height={30}
                                width={30}
                            />
                            :
                            <p style={{margin: 0}}>{message}</p>
                        }
                    </Row>
                </Form>
            </InnerContainer>
        </Container>
    )
}

export default ContactPopup