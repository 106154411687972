import React from "react"
import PropTypes from "prop-types"
import "./layout.css"
import MediaQuery from "react-responsive"
import styled from 'styled-components';
import Header from "./header";
import MobileNavbar from "./mobile-navbar";
import AboutContact from "./about/about-contact";
import ContactPopup from "./contact/contact-popup";
import { useRecoilValue } from "recoil";
import { showingContact, showingTeamMember } from "../state/atoms";
import { AnimatePresence } from "framer-motion";
import TeamMemberPopup from "./team/team-popup";

const Main = styled.main`
  color: #161F45;
  position: relative;
`

const Layout = ({ children }) => {

  const showContact = useRecoilValue(showingContact)
  const showTeamMember = useRecoilValue(showingTeamMember)

  return (
    <>
    <AnimatePresence>
      {showContact &&  <ContactPopup /> }

    </AnimatePresence>
    <AnimatePresence>
      {showTeamMember &&  <TeamMemberPopup /> }

    </AnimatePresence>
    <MediaQuery minDeviceWidth={550}>
        <Header />
      </MediaQuery>
      <MediaQuery maxDeviceWidth={550}>
        <MobileNavbar />
      </MediaQuery>
      <Main>{children}</Main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
