import styled from 'styled-components';
import { colors } from './colors';

export const MainButton = styled.button`
    color: white;
    cursor: pointer;
    background: ${colors.accent};
    outline: none;
    border: none;
    padding: 10px 20px;
    text-transform: uppercase;
    font-weight: bold;
    transition: 0.2s;
    :hover {
        background: #ab2025;
    }
`