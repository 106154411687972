import React, { Component } from "react"

//NPM
import styled from "styled-components"
import { Link } from "gatsby"
import HamburgerMenu from "react-hamburger-menu"
import Menu from "./menu"
import { colors } from "../style/colors"

const NavContainer = styled.div`
  background-color: white;
  width: 100%;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 100;
  border-bottom: 1px solid #f1f1f1;
`
const InnerContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const NavLogo = styled.img`
  margin: 0 auto;
  display: block;
  max-width: 100px;
`
const MenuContainer = styled.div``

class MobileNavbar extends Component {
  state = {
    isOpen: false,
  }
  render() {
    return (
      <NavContainer>
        <InnerContainer>
          <Link to="/">
            <NavLogo src={require("../images/logo.png")} />
          </Link>
          <MenuContainer>
            <HamburgerMenu
              isOpen={this.state.isOpen}
              menuClicked={() => this.setState({ isOpen: !this.state.isOpen })}
              width={23}
              height={15}
              strokeWidth={2}
              rotate={0}
              color={colors.main}
              borderRadius={0}
              animationDuration={0.5}
            />
            <Menu
              isOpen={this.state.isOpen}
              close={() => this.setState({isOpen: false})}
            />
          </MenuContainer>
        </InnerContainer>
      </NavContainer>
    )
  }
}

export default MobileNavbar
