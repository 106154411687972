import styled from 'styled-components';

export const SplitColumn = styled.section`
    display: grid;
    grid-template-columns: 50% 50%;
    
    @media (max-width: 768px) {
        grid-template-columns: 100%;

    }
     
`